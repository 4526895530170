import { Box, Card } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ToolBar } from '@/common/components'
import CustomSnackbar from '@/common/components/CustomSnackbar/CustomSnackbar'
import course, { CourseSearch, CourseSearchFormContext, courseSlice, useSearchCourses } from '@/features/course'
import { usePageVisited } from '@/hooks'
import { MenusNavItem } from '@/menus'
import { RootState, useTypedDispatch, useTypedSelector } from '@/store'
import CustomPalette from '@/theme/CustomPalette'
import { CourseList } from '../components/CourseList/CourseList'
import { customSnackbarStyles } from './Details/features/Variants/VariantsStyle'

type PageProps = {
  query?: string
}

const Courses = ({ ...rest }: PageProps): JSX.Element => {
  const { t, i18n } = useTranslation('general')
  usePageVisited('Courses')
  const dispatch = useTypedDispatch()
  const snackbarOpen = useTypedSelector((state: RootState) => state.courseSlice.isCourseEdited)

  const openMenu = () => {
    dispatch(courseSlice.actions.openCreateCourseMenu(true))
  }

  const closeMenu = () => {
    dispatch(courseSlice.actions.setExpandDialog(false))
    dispatch(courseSlice.actions.openCreateCourseMenu(false))
  }

  const openUpdateMenu = (courseCode: string) => {
    dispatch(courseSlice.actions.openEditCourseMenu(true))
    dispatch(courseSlice.actions.setCourseMenu(courseCode))
  }

  const closeUpdateMenu = () => {
    dispatch(courseSlice.actions.openEditCourseMenu(false))
  }

  const handleSnackbarClose = () => {
    dispatch(courseSlice.actions.setIsCourseEdited(false))
  }

  const courseMenus: MenusNavItem[] = [
    {
      index: '1',
      component: async () => {
        const module = await import(/* webpackChunkName: "CourseMenu" */ '@/features/course/menus/CourseMenu')
        return {
          default: module.CourseMenu
        }
      },
      props: { title: t('create_course') ?? 'Create Course' }
    }
  ]

  const UpdateMenus: MenusNavItem[] = [
    {
      index: '1',
      component: async () => {
        const module = await import('@/features/course/menus/UpdateCourseMenu/UpdateCourseMenu')
        return {
          default: module.UpdateCourseMenu
        }
      },
      props: { title: 'Edit course' }
    }
  ]

  const titles: string[] = [
    t('course_internal_reference'),
    t('catalog_course_title'),
    t('catalog_languages'),
    t('catalog_actions')
  ]

  return (
    <>
      <ToolBar sx={{ marginTop: '-43px', marginLeft: '-8px' }} title={t('courseCatalogue') ?? 'Course Catalogue'} />
      <CourseSearchFormContext>
        <Box sx={{ mt: 4 }}>
          <Card
            {...rest}
            sx={{
              border: `1px solid ${CustomPalette.SecondaryNightBlue200}`,
              borderRadius: '20px',
              boxShadow: 'none'
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <CourseSearch placeholder={t('search_courses_title_ID')} key={i18n.language} />
              <ToolBar
                customButtonStyles={{ marginTop: '24px', marginRight: '24px', marginBottom: '24px' }}
                withCustomButton
                customButtonLabel={t('create_course') ?? undefined}
                altCustomButtonLabel="Create course"
                menus={courseMenus}
                openAction={openMenu}
                closeAction={closeMenu}
              />
            </Box>
            <CourseList
              data={course}
              routeLabel="courses"
              emptyList={t('noCourse')}
              titles={titles}
              menus={UpdateMenus}
              openAction={openUpdateMenu}
              closeAction={closeUpdateMenu}
              useSearchList={useSearchCourses}></CourseList>
          </Card>
        </Box>
      </CourseSearchFormContext>
      {snackbarOpen && (
        <CustomSnackbar
          styles={customSnackbarStyles}
          open={snackbarOpen ?? false}
          onClose={handleSnackbarClose}
          message={'Course edited successfully.'}
          severity={'success'}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={5000}
        />
      )}
    </>
  )
}

export default Courses
