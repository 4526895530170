import { UserDto } from '@/services/api/tenant/user'
import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { ParticipantsState } from './types'

const initialState: ParticipantsState = {
  participantViews: {
    openImportParticipantsMenu: false,
    openAddManuallyParticipantsMenu: false,
    openCsvImportErrors: false,
    openEmptyParticipantListMode: false
  },
  openCreateManagerForm: false,
  openCreateManagerDetailsForm: false,
  newManagerUser: null,
  editManagerUser: null,
  selectedParticipant: null,
  selectedParticipants: [],
  paginationDisabled: null,
  triggerAllUsersFetch: false,
  allSelectedStatus: {
    isChecked: false,
    isIndeterminate: false
  },
  snackbarContents: {
    open: false,
    type: 'info',
    content: ''
  },
  dialogVisibility: {
    isArchiveDialogOpen: false,
    selectedUser: null
  }
}

export const participantsSlice = createSlice({
  name: 'participantsSlice',
  initialState,
  reducers: {
    openImportParticipantsMenu(state, action) {
      state.participantViews.openImportParticipantsMenu = action.payload
    },
    openAddManuallyParticipantsMenu(state, action) {
      state.participantViews.openAddManuallyParticipantsMenu = action.payload
    },
    openCsvImportErrors(state, action) {
      state.participantViews.openCsvImportErrors = action.payload
    },
    openEmptyParticipantListMode(state, action) {
      state.participantViews.openEmptyParticipantListMode = action.payload
    },
    setOpenCreateManagerForm(state, action) {
      state.openCreateManagerForm = action.payload
    },
    setOpenCreateManagerDetailsForm(state, action) {
      state.openCreateManagerDetailsForm = action.payload
    },
    setNewManagerUser(state, action) {
      state.newManagerUser = action.payload
    },
    setEditManagerUser(state, action) {
      state.editManagerUser = action.payload
    },
    setSelectedParticipant(state, action) {
      state.selectedParticipant = action.payload
    },
    setSelectedParticipants(state, action) {
      state.selectedParticipants = action.payload
    },
    setPaginationDisabled(state, action) {
      state.paginationDisabled = action.payload
    },
    setAllSelectedStatus(state, action) {
      state.allSelectedStatus.isChecked = action.payload.isChecked
      state.allSelectedStatus.isIndeterminate = action.payload.isIndeterminate
    },
    setTriggerAllUsersFetch(state, action) {
      state.triggerAllUsersFetch = action.payload
    },
    setSnackbarContents(state, action) {
      state.snackbarContents.open = action.payload.open
      state.snackbarContents.type = action.payload.type
      state.snackbarContents.content = action.payload.content
    },
    openArchiveDialog(state, action: { payload: { user: UserDto | null } }) {
      state.dialogVisibility.isArchiveDialogOpen = true
      state.dialogVisibility.selectedUser = action.payload.user
    },
    closeArchiveDialog(state) {
      state.dialogVisibility.isArchiveDialogOpen = false
      state.dialogVisibility.selectedUser = null
    }
  }
})

export const participantReducer = persistReducer(
  {
    key: 'rtk:participant',
    storage,
    whitelist: ['']
  },
  participantsSlice.reducer
)
